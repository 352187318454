import firestore from "firebase/firestore";
import { CanvasData } from "../../views/common/canvas/type";

export const canvasConverter = {
  toFirestore(post: CanvasData[]): firestore.DocumentData {
    return { data: post.map((v) => ({ ...v, path: arrayToObj(v.path) })) };
  },
  fromFirestore(
    snapshot: firestore.QueryDocumentSnapshot,
    options: firestore.SnapshotOptions
  ): CanvasData[] {
    const doc = snapshot;
    const data = doc.data().data;

    const newCanvasData = data.map((item: any) => {
      return {
        ...item,
        path: objToArray(item.path),
      } as CanvasData;
    });
    return newCanvasData;
  },
};

const arrayToObj = (array: any[]) => {
  const obj: any = {};
  array.forEach((item, index) => {
    obj[index] = item;
  });
  return obj;
};

const objToArray = (obj: any) => {
  const array: any[] = [];
  for (const key in obj) {
    array[Number(key)] = obj[key];
  }
  return array;
};
