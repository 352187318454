import DeleteIcon from "@mui/icons-material/Delete";
import RedoIcon from "@mui/icons-material/Redo";
import UndoIcon from "@mui/icons-material/Undo";
import { Button, Stack } from "@mui/material";

type Prop = {
  clear: () => void;
  undo: () => void;
  redo: () => void;
  isCompetitive?: boolean;
};

export const CanvasButtons = (prop: Prop) => {
  const { undo, redo, clear, isCompetitive } = prop;
  const buttonArray = isCompetitive
    ? [{ name: "clear", function: clear, startIcon: <DeleteIcon /> }]
    : [
        //ドラッグモードの需要がないと思われるので削除
        //{ name: "Drag Mode", function: makeDrawingModeOff },
        //{ name: "Pen Mode", function: makeDrwaingModeOn },
        { name: "clear", function: clear, startIcon: <DeleteIcon /> },
        { name: "undo", function: undo, startIcon: <UndoIcon /> },
        { name: "redo", function: redo, startIcon: <RedoIcon /> },
      ];

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={0.5}
    >
      {buttonArray.map((v) => (
        <Button
          variant="contained"
          color="primary"
          onClick={v.function}
          sx={{ mx: 0.5 }}
          key={v.name}
          startIcon={v.startIcon}
        >
          {v.name}
        </Button>
      ))}
    </Stack>
  );
};
