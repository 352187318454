import { Box, Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IdCopyForm } from "../common/IdCopyForm";

export const Top = ({ id }: { id: string }) => {
  const navigate = useNavigate();
  return (
    <Box>
      <IdCopyForm id={id} />
      <p>クリックするとidがコピーできます</p>

      <Button
        variant="contained"
        onClick={() => {
          navigate(`player/${id}`);
        }}
      >
        解く
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          navigate(`viewer/${id}`);
        }}
      >
        見る
      </Button>
    </Box>
  );
};
