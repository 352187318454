import { Box, Button, Stack } from "@mui/material";
import { useState } from "react";
import { fabric } from "fabric";
import { eraserBrush } from "./lib/eraserBrush";
import eraser from "../../image/eraser.png";

const select: React.CSSProperties = {
  border: "solid 3px blue",
  height: "50px",
};

const notSelect: React.CSSProperties = {
  height: "53px",
};

type Prop = {
  mode: string;
  setMode: (mode: string) => void;
  canvasRef: React.MutableRefObject<fabric.Canvas | undefined>;
};

export const EraserSelect = (prop: Prop) => {
  const { canvasRef, mode, setMode } = prop;

  const changeToEraser = () => {
    setMode("eraser");
    if (canvasRef.current !== undefined) {
      //fabricのcustom buildを利用しているため@types/fabricには定義されていない
      //そのため、jsで記述している
      eraserBrush(canvasRef);
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "75px" }}
    >
      <Button
        sx={{ width: "50px", minWidth: "50px" }}
        style={mode === "eraser" ? select : notSelect}
        onClick={changeToEraser}
      >
        <img
          src={eraser}
          alt={"eraser"}
          style={{
            width: "40px",
            height: "40px",
          }}
        />
      </Button>
    </Stack>
  );
};
