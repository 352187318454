import { Box, Slider, Typography } from "@mui/material";
import { fabric } from "fabric";
import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";

const theme = createTheme({
  palette: {
    pen: {
      main: "#ef9a9a",
    },
    eraser: {
      main: "#90caf9",
    },
  },
});

declare module "@mui/material/styles" {
  interface Palette {
    pen: Palette["primary"];
    eraser: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    pen?: PaletteOptions["primary"];
    eraser?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Slider" {
  interface SliderPropsColorOverrides {
    pen: true;
    eraser: true;
  }
}

type Prop = {
  width: number;
  setWidth: React.Dispatch<React.SetStateAction<number>>;
  type: "pen" | "eraser";
};

export const WidthSelect = (prop: Prop) => {
  const { width, setWidth, type } = prop;

  const handleWidth = (event: Event, newValue: number | number[]) => {
    if (typeof newValue !== "number") {
      return;
    }
    setWidth(newValue);
  };
  return (
    <Box>
      <Typography variant="caption">
        {type === "pen" ? "ペン" : "消しゴム"}の太さ
      </Typography>
      <ThemeProvider theme={theme}>
        <Slider
          aria-label="width"
          value={width}
          valueLabelDisplay="auto"
          onChange={handleWidth}
          min={1}
          step={1}
          marks
          max={type === "pen" ? 15 : 50}
          color={type}
        />
      </ThemeProvider>
    </Box>
  );
};
