import { collection, doc, onSnapshot, setDoc } from "firebase/firestore";
import { db } from "../..";
import { CanvasData } from "../../views/common/canvas/type";
import { canvasConverter } from "./firebaseConverter";

export const loadCanvasData = (
  id: string,
  setCanvasData: (canvasData: CanvasData[] | null) => void,
  setIsCanvasDataLoaded: (isCanvasDataLoaded: boolean) => void
) => {
  if (id === "") {
    return () => {};
  }
  const colref = collection(db, "canvasData");
  const docref = doc(colref, id).withConverter(canvasConverter);
  const stopUserDataSnapshot = onSnapshot(docref, (doc) => {
    const newUserData = doc.data();
    if (newUserData) {
      setCanvasData(newUserData);
    } else {
      console.log("No such document!");
    }
    setIsCanvasDataLoaded(true);
  });
  return stopUserDataSnapshot;
};

export const saveCanvasData = async (id: string, canvasData: CanvasData[]) => {
  const docRef = doc(db, "canvasData", id).withConverter(canvasConverter);
  await setDoc(docRef, canvasData);
  return;
};
