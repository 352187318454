import { CanvasData } from "../type";

export const canvasDataToCanvasObject = (data: CanvasData[]) => {
  const objects = data.map((item) => {
    return { ...objectsTemplate, ...item };
  });
  return objects;
};

const objectsTemplate = {
  type: "path",
  version: "5.2.4",
  originX: "left",
  originY: "top",
  fill: null,
  strokeDashArray: null,
  strokeLineCap: "round",
  strokeDashOffset: 0,
  strokeLineJoin: "round",
  strokeUniform: false,
  strokeMiterLimit: 10,
  scaleX: 1,
  scaleY: 1,
  angle: 0,
  flipX: false,
  flipY: false,
  opacity: 1,
  shadow: null,
  visible: true,
  backgroundColor: "",
  fillRule: "nonzero",
  paintFirst: "fill",
  skewX: 0,
  skewY: 0,
  erasable: true,
};
