import { CanvasData } from "../views/common/canvas/type";
import { useEffect, useRef, useState } from "react";
import { loadCanvasData } from "../api/firestore/loadCanvasData";

export const useCanvasData = (id: string | undefined) => {
  const [canvasData, setCanvasData] = useState<CanvasData[] | null>(null);
  const [isCanvasDataLoaded, setIsCanvasDataLoaded] = useState(false);
  const stopUserDataSnapshotRef = useRef(() => {});
  useEffect(() => {
    if (id === undefined) {
      return;
    }
    if (id === "") {
      setIsCanvasDataLoaded(true);
      return;
    }
    stopUserDataSnapshotRef.current();
    stopUserDataSnapshotRef.current = loadCanvasData(
      id,
      setCanvasData,
      setIsCanvasDataLoaded
    );
  }, [id]);
  return { canvasData, isCanvasDataLoaded };
};
