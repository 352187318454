import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { CommonFooter } from "./views/common/CommonFooter";
import { NowLoading } from "./views/common/NowLoading";
import { Marker } from "./views/pages/Marker";
import { PuzzlePlayer } from "./views/pages/PuzzlePlayer";
import { PuzzleViewer } from "./views/pages/PuzzleViewer";
import { Top } from "./views/pages/Top";

const App = () => {
  const id = Math.random().toString(36).slice(-8);

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <Wrapper>
            <NowLoading />
          </Wrapper>
        }
      />
      <Route path="player/:id" element={<PuzzlePlayer />} />
      <Route path="viewer/:id" element={<PuzzleViewer />} />
      <Route path="marker/:id" element={<Marker />} />
      <Route path="/" element={<Top id={id} />} />
      <Route path="*" element={<Top id={id} />} />
    </Routes>
  );
};

export default App;

export const Wrapper = (props: React.PropsWithChildren<{}>) => {
  return (
    <>
      <Box sx={{ mt: 10, mx: "auto", px: 1 }} maxWidth="lg">
        {props.children}
      </Box>
      <CommonFooter />
    </>
  );
};
