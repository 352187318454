import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { fabric } from "fabric";
import { useEffect, useState } from "react";
import { ColorSelect } from "./canvasConfigcomponents/ColorSelect";
import { EraserSelect } from "./canvasConfigcomponents/EraserSelect";
import { WidthSelect } from "./canvasConfigcomponents/WidthSelect";

type Prop = {
  canvasRef: React.MutableRefObject<fabric.Canvas | undefined>;
  mode: string;
  setMode: (mode: string) => void;
};

export const CanvasUtil = (prop: Prop) => {
  const { canvasRef, mode, setMode } = prop;

  const [penWidth, setPenWidth] = useState(5);
  const [eraserWidth, setEraserWidth] = useState(20);
  useEffect(() => {
    if (canvasRef.current === undefined) {
      return;
    }
    switch (mode) {
      case "pen":
        if (canvasRef.current.freeDrawingBrush.width !== penWidth) {
          canvasRef.current.freeDrawingBrush.width = penWidth;
        }
        break;
      case "eraser":
        if (canvasRef.current.freeDrawingBrush.width !== eraserWidth) {
          canvasRef.current.freeDrawingBrush.width = eraserWidth;
        }
        break;
    }
  }, [penWidth, eraserWidth, mode, canvasRef]);
  return (
    <Box sx={{ maxWidth: 610, m: "auto" }}>
      <Box sx={{ p: 1 }}>
        <Stack
          direction="row"
          spacing={0.5}
          sx={{ overflowX: "auto", mx: "auto" }}
        >
          <EraserSelect canvasRef={canvasRef} mode={mode} setMode={setMode} />
          <ColorSelect canvasRef={canvasRef} mode={mode} setMode={setMode} />
        </Stack>
        <WidthSelect width={penWidth} setWidth={setPenWidth} type="pen" />
        <WidthSelect
          width={eraserWidth}
          setWidth={setEraserWidth}
          type="eraser"
        />
      </Box>
    </Box>
  );
};
