import { Box, Button, Stack } from "@mui/material";
import { fabric } from "fabric";
import { useState } from "react";
import pen_black from "../../image/pen_black.png";
import pen_brown from "../../image/pen_brown.png";
import pen_gray from "../../image/pen_gray.png";
import pen_lime from "../../image/pen_lime.png";
import pen_orange from "../../image/pen_orange.png";
import pen_purple from "../../image/pen_purple.png";
import pen_red from "../../image/pen_red.png";
import pen_skyblue from "../../image/pen_skyblue.png";
import pen_white from "../../image/pen_white.png";
import pen_yellow from "../../image/pen_yellow.png";

//画像出典はいらすとや
const colorList = [
  { name: "red", color: "#e74c3c", icon: pen_red },
  { name: "orange", color: "#e67e22", icon: pen_orange },
  { name: "yellow", color: "#f1c40f", icon: pen_yellow },
  { name: "lime", color: "#2ecc71", icon: pen_lime },
  { name: "skyblue", color: "#3498db", icon: pen_skyblue },
  { name: "purple", color: "#9b59b6", icon: pen_purple },
  { name: "brown", color: "#8c5a24", icon: pen_brown },
  { name: "black", color: "#000000", icon: pen_black },
  { name: "gray", color: "#aaaaaa", icon: pen_gray },
  { name: "white", color: "#eeeeee", icon: pen_white },
];

const select: React.CSSProperties = {
  border: "solid 3px red",
  height: "50px",
};

const notSelect: React.CSSProperties = {
  height: "53px",
};

type Prop = {
  mode: string;
  setMode: (mode: string) => void;
  canvasRef: React.MutableRefObject<fabric.Canvas | undefined>;
};

export const ColorSelect = (prop: Prop) => {
  const { canvasRef, mode, setMode } = prop;
  const [selectColor, setSelectColor] = useState<string>(
    canvasRef.current?.freeDrawingBrush.color ?? "#000000"
  );
  const changePenColor = (colorValue: string) => {
    //colorvalue sample: #fff, #c0ffee, etc.
    setMode("pen");
    setSelectColor(colorValue);
    if (canvasRef.current !== undefined) {
      const penWidth = canvasRef.current.freeDrawingBrush.width;
      canvasRef.current.freeDrawingBrush = new fabric.PencilBrush(
        canvasRef.current
      );
      canvasRef.current.freeDrawingBrush.width = penWidth;
      canvasRef.current.freeDrawingBrush.color = colorValue;
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "75px" }}
    >
      {colorList.map((v) => (
        <Button
          sx={{ width: "50px", minWidth: "50px" }}
          style={mode === "pen" && selectColor === v.color ? select : notSelect}
          onClick={() => {
            changePenColor(v.color);
          }}
          key={v.name}
        >
          <img
            src={v.icon}
            alt={v.name}
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </Button>
      ))}
    </Stack>
  );
};
