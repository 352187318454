import { Box, Container, Typography, Link } from "@mui/material";
import { FC } from "react";

export const CommonFooter: FC<{}> = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        my: 10,
      }}
    >
      <Container maxWidth="sm">
        <Typography variant="body1">
          Pentool is a tool for logical puzzle competitions.
        </Typography>
      </Container>
    </Box>
  );
};
