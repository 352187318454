import { Box, Button } from "@mui/material";
import { fabric } from "fabric";
import { useCallback, useEffect, useRef, useState } from "react";
import { saveCanvasData } from "../../../api/firestore/loadCanvasData";
import { CanvasUtil } from "./canvasConfig/CanvasConfig";
import { CanvasButtons } from "./canvasConfig/canvasConfigcomponents/CanvasButtons";
import { canvasDataToCanvasJSON } from "./lib/canvasDataToCanvasJson";
import { CanvasData } from "./type";

const CANVAS_ID = "canvas";

export const CanvasPlayer = ({
  imageUrl,
  maxWidth,
  id,
}: {
  imageUrl: string;
  maxWidth?: number;
  id: string;
}) => {
  const canvasRef = useRef<fabric.Canvas | undefined>(undefined);
  const canvasLapper = useRef<HTMLDivElement>(null);
  const isCanvasExistRef = useRef(false);
  const beforeImageUrlRef = useRef("");
  const initialWidthRef = useRef(300);
  const canvasHistoryRef = useRef<CanvasData[][]>([[]]);
  const canvasHistoryIndexRef = useRef(0);
  const [mode, _setMode] = useState("pen");
  const modeRef = useRef("pen");
  const setMode = (mode: string) => {
    modeRef.current = mode;
    _setMode(mode);
  };

  const saveData = useCallback(() => {
    if (canvasRef.current === undefined) {
      return;
    }
    const index = canvasHistoryIndexRef.current;
    const canvasHistory = canvasHistoryRef.current[index];
    saveCanvasData(id, canvasHistory);
  }, [id]);

  const setZoomWidthHeight = (canvasWidth: number, canvasHeight: number) => {
    if (canvasRef.current === undefined) {
      return;
    }
    canvasRef.current?.setZoom(canvasWidth / initialWidthRef.current);
    canvasRef.current?.setWidth(canvasWidth);
    canvasRef.current?.setHeight(canvasHeight);
  };

  const loadCanvasFromJSON = (JSON: string) => {
    if (canvasRef.current === undefined) {
      return;
    }
    const canvasWidth = canvasRef.current.width as number;
    const canvasHeight = canvasRef.current.height as number;
    canvasRef.current.loadFromJSON(JSON, () => {
      setZoomWidthHeight(canvasWidth, canvasHeight);
    });
  };

  const loadBackgroundImage = useCallback(
    (canvasWidth: number) => {
      fabric.Image.fromURL(imageUrl, (image) => {
        const imageWidth = image.width;
        const imageHeight = image.height;
        if (!imageWidth || !imageHeight) {
          return;
        }
        const canvasHeight = (canvasWidth / imageWidth) * imageHeight;
        setZoomWidthHeight(canvasWidth, canvasHeight);
        /*canvasRef.current?.setBackgroundImage(image, () => {
          image.scaleToWidth(canvasWidth);
          makeBackgroundImageUnerasable(canvasRef);
        });*/
      });
    },
    [imageUrl]
  );

  const recordHistory = (e: any) => {
    const target = e.path;
    const canvasData: CanvasData = {
      left: target.left!,
      top: target.top!,
      width: target.width!,
      height: target.height!,
      stroke: target.stroke!,
      strokeWidth: target.strokeWidth!,
      path: JSON.parse(JSON.stringify(target.path!)),
      globalCompositeOperation:
        modeRef.current === "eraser" ? "destination-out" : "source-over",
    };
    if (canvasRef.current === undefined) {
      return;
    }
    if (canvasHistoryRef.current.length > canvasHistoryIndexRef.current + 1) {
      canvasHistoryRef.current = canvasHistoryRef.current.slice(
        0,
        canvasHistoryIndexRef.current
      );
    }
    const beforeHistory =
      canvasHistoryRef.current[canvasHistoryIndexRef.current];
    const canvasHistory = [...beforeHistory, canvasData];
    console.log(canvasHistory);
    console.log(JSON.stringify(canvasHistory).length);
    canvasHistoryRef.current.push(canvasHistory);
    canvasHistoryIndexRef.current++;
    saveData();
  };

  const undo = () => {
    if (canvasRef.current === undefined) {
      return;
    }
    if (canvasHistoryIndexRef.current === 0) {
      return;
    }
    canvasHistoryIndexRef.current--;
    const canvasData = canvasHistoryRef.current[canvasHistoryIndexRef.current];
    const JSON = canvasDataToCanvasJSON(canvasData, canvasRef);
    loadCanvasFromJSON(JSON);
    saveData();
  };
  const redo = () => {
    if (canvasRef.current === undefined) {
      return;
    }
    if (canvasHistoryIndexRef.current + 1 >= canvasHistoryRef.current.length) {
      return;
    }
    canvasHistoryIndexRef.current++;
    const canvasHistory =
      canvasHistoryRef.current[canvasHistoryIndexRef.current];
    const JSON = canvasDataToCanvasJSON(canvasHistory, canvasRef);
    loadCanvasFromJSON(JSON);
    saveData();
  };

  const clear = () => {
    if (canvasRef.current === undefined) {
      return;
    }
    const canvasHistory = canvasHistoryRef.current[0];
    const JSON = canvasDataToCanvasJSON(canvasHistory, canvasRef);
    canvasRef.current.loadFromJSON(JSON, () => {
      const canvasWidth = canvasRef.current!.width as number;
      loadBackgroundImage(canvasWidth);
      canvasHistoryRef.current = [[]];
      canvasHistoryIndexRef.current = 0;
    });
    saveData();
  };

  useEffect(() => {
    if (canvasRef.current === undefined) {
      return;
    }
    if (beforeImageUrlRef.current !== imageUrl) {
      beforeImageUrlRef.current = imageUrl;
      canvasRef.current.clear();
      const canvasWidth = canvasRef.current!.width as number;
      loadBackgroundImage(canvasWidth);
      canvasHistoryRef.current = [[]];
      canvasHistoryIndexRef.current = 0;
      saveData();
    }
  }, [imageUrl]);

  useEffect(() => {
    if (
      canvasLapper.current &&
      (imageUrl ?? "") !== "" &&
      !isCanvasExistRef.current
    ) {
      isCanvasExistRef.current = true;
      beforeImageUrlRef.current = imageUrl;
      const canvasWidth = canvasLapper.current!.offsetWidth;

      fabric.Image.fromURL(imageUrl, (image) => {
        const imageWidth = image.width;
        const imageHeight = image.height;
        if (!imageWidth || !imageHeight) {
          return;
        }
        const canvasHeight = (canvasWidth / imageWidth) * imageHeight;
        image.scaleToWidth(canvasWidth);
        canvasRef.current = new fabric.Canvas(CANVAS_ID, {
          isDrawingMode: true, // 手書きモード
          allowTouchScrolling: true, //スマホで拡大出来るようにする
          width: canvasWidth,
          height: canvasHeight,
          backgroundColor: "rgba(0,0,0,0)",
          //backgroundImage: image,
        });
        initialWidthRef.current = canvasWidth;
        canvasRef.current.on("path:created", recordHistory);
        canvasRef.current.freeDrawingBrush.width = 5;
        saveData();
      });
    }
  }, []);

  const onResize = useCallback(() => {
    if (canvasLapper.current && imageUrl) {
      const canvasWidth = canvasLapper.current.offsetWidth;
      fabric.Image.fromURL(imageUrl, (image) => {
        const imageWidth = image.width;
        const imageHeight = image.height;
        if (!imageWidth || !imageHeight) {
          return;
        }
        const canvasHeight = (canvasWidth / imageWidth) * imageHeight;
        canvasRef.current?.setZoom(canvasWidth / initialWidthRef.current);
        canvasRef.current?.setWidth(canvasWidth);
        canvasRef.current?.setHeight(canvasHeight);
        /*canvasRef.current?.setBackgroundImage(image, () => {
          image.scaleToWidth(canvasWidth);
          makeBackgroundImageUnerasable(canvasRef);
        });*/
      });
    }
  }, [imageUrl]);

  window.onresize = onResize;

  return (
    <Box>
      <div
        ref={canvasLapper}
        id="canvas-lapper"
        style={{
          width: "100%",
          maxWidth,
          margin: "auto",
          position: "relative",
        }}
      >
        <img
          src={imageUrl}
          alt="背景画像"
          style={{
            width: "100%",
            maxWidth,
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
        <canvas id={CANVAS_ID} />
      </div>
      <CanvasButtons
        undo={undo}
        redo={redo}
        clear={clear}
        isCompetitive={true}
      />
      <CanvasUtil canvasRef={canvasRef} mode={mode} setMode={setMode} />
      <Button onClick={saveData}>保存</Button>
    </Box>
  );
};
