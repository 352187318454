import { canvasDataToCanvasObject } from "./canvasDataToCanvasObject";
import { CanvasData } from "../type";

export const canvasDataToCanvasJSON = (
  canvasData: CanvasData[],
  canvasRef: React.MutableRefObject<fabric.Canvas | undefined>
): any => {
  if (canvasRef.current === undefined) {
    return;
  }
  const _JSON = canvasRef.current.toJSON() as any;
  const objects = canvasDataToCanvasObject(canvasData);
  _JSON.objects = objects;
  return _JSON;
};
