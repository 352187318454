import { Snackbar, TextField } from "@mui/material";
import { useState } from "react";

type IdCopyFormProps = {
  id: string;
};
export const IdCopyForm = ({ id }: IdCopyFormProps) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(id);
  };
  return (
    <>
      <TextField
        id="filled-read-only-input"
        label="id"
        defaultValue={id}
        InputProps={{
          readOnly: true,
        }}
        sx={{ cursor: "pointer", mb: 1 }}
        variant="filled"
        onClick={handleClick}
      />
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="idをクリップボードにコピーしました"
      />
    </>
  );
};
