import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { CanvasPlayer } from "../common/canvas/CanvasPlayer";

export const PuzzlePlayer = () => {
  const param = useParams<{ id: string }>();
  if (!param.id) return <>urlが不正です</>;
  return (
    <Box sx={{ m: "auto" }}>
      <CanvasPlayer
        id={param.id}
        maxWidth={600}
        imageUrl={`https://firebasestorage.googleapis.com/v0/b/puzzlepentool.appspot.com/o/yajilin.png?alt=media&token=fbdc1dda-5235-43c2-afa0-3e15c0f02a0c`}
      />
    </Box>
  );
};
